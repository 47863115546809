* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body {
  font-family: "Open Sans"
}

/* Styling for Registration and log in pages */

.layout-container {
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 1024px;
  width: 100%;
}

.left-side {
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 1024px;
  max-width: 1216px;
}

.logo-section {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  align-items: normal;
  margin: 15px 0px 0px 0px;
}

.logo-section img {
  width: 340px;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  justify-content: center;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  width: 396px;
}

.header-section h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.header-section p {
  font-size: 16px;
  font-weight: 400;
  align-items: center;
}

/* Form styling */

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 396px;
  margin: 0 auto;
  max-height: 644px;
  width: 100%;
  max-width: 396px;
  margin: 0 auto;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

.form-label {
  font-size: 15px;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 396px;
  height: 44px;
}

.form-input::placeholder {
  font-size: 14px;
  color: #999999;
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.password-input-wrapper input {
  padding-right: 40px;
  width: 396px;
  height: 44px;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

/* Checkbox styling */

.remember-me {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.remember-me input[type="checkbox"] {
  margin-right: 2px;
}

.remember.remember-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
}

.remember-me-left {
  display: flex;
  align-items: center;
}

.forgot-password-link {
  text-decoration: none;
  color: #002DCC;
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
}

.forgot-password-link:hover {
  text-decoration: none;
}

.submit-button:disabled {
  background-color: lightgrey;
  color: darkgrey;
  cursor: not-allowed;
}

.submit-button {
  width: 100%;
  max-width: 396px;
  padding: 10px;
  font-size: 18px;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #002DCC;
  margin-top: 6px;
  color: white;
  border: none;
  height: 44px;
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: small;
}

/* styles for External sign ins buttons */

.external-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin: 6px;
}

.google-button,
.linkedin-button {
  width: 100%;
  max-width: 396px;
  max-height: 44px;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  color: #1A1A1A;
  border: 1px solid #ccc;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.link-to {
  margin-top: 10px;
}

.link-text {
  font-weight: 400;
  font-size: 16px;
  margin-left: 5px;
}

.link-text a {
  text-decoration: none;
  color: #002DCC;
  font-weight: 400;
}

.right-side {
  max-height: 1024px;
  max-width: 688px;
  padding: 0;
  box-sizing: border-box;
}

.image-right {
  width: 100%;
  /* height: 100%; */
  object-fit: fill;
}

@media (min-width: 1440px) {
  .layout-container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .layout-container {
    flex-direction: column;
    width: 768px;
    height: 800px;
  }

  .right-side .image {
    width: 100%;
    height: 100%;
  }
}

/* Error page styling */

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F5F7FA;
  font-size: 24px;
  text-align: center;
}

.error-emoji {
  font-size: 80px;
  animation: bounce 2s infinite;
}

.error-text {
  margin-top: 20px;
  color: #333;
  font-weight: 600;
}

.error-link {
  margin-top: 30px;
  font-size: 18px;
  text-decoration: none;
  color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.error-link:hover {
  background-color: #1A4CFF;
  color: white;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Verify-email img {
  animation: fadeIn 1s ease-out;
  max-width: 100%;
  height: auto;
}

.Verify-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 24px;
}

/* styling for loader */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#002DCC 0 0);
  background:
    var(--c) 0% 50%,
    var(--c) 50% 50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}

@keyframes l1 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%
  }

  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%
  }

  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%
  }

  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%
  }

  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%
  }
}

/* styling for resetLink page */

.reset {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 396px;
  margin: 0 auto;
  max-height: 644px;
}

.reset h3 {
  font-weight: 600;
  font-size: 32px;
}

.reset p {
  font-weight: 400;
  font-size: 16px;
}

.reset div {
  text-align: center;
  margin-top: 10px;
}

/* navbar styling */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  height: 60px;
}

.navbar-left {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.navbar-heading {
  padding: 10px 10px 2px 10px;
  font-size: 27px;
  margin-right: 15px;
  font-weight: 600;
  color: #1A1A1A;

}

.navbar-heading a {
  text-decoration: none;
  color: inherit;

}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu-item {
  margin-right: 23px;
  cursor: pointer;
  color: #999999;
}

.menu-item a {
  text-decoration: none;
  color: inherit;
}

.menu-item a:hover {
  color: #002DCC;
}

.menu-item a.active-link {
  color: #002DCC;
  ;
}

.navbar-right {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  border-radius: 24px;
  width: 80px;
  height: 40px;
}

.bell-icon {
  cursor: pointer;
}

/*  bell Modal container */

.bell-modal {
  position: fixed;
  top: 0px;
  right: 0;
  width: 360px;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 1000;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: auto;
  transform: translateX(100%);
  animation: none;
}

.bell-modal.open {
  /* margin-right: 40px; */
  animation: slideIn 0.8s ease-out forwards;
}

.bell-modal {
  animation: slideOut 0.8s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.bell-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.bell-modal-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.close-button-bell {
  background: none;
  width: 20px;
  border: none;
  cursor: pointer;
}

.notification-message-style {
  width: 100%;
  text-align: left;
  border: 1px solid #DDDDDDDD;
  border-radius: 5px;
  min-height: 33px;
  background-color: #F5F7FA;
  margin-bottom: 7px;
  padding: 7px;
  font-size: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-read-notification {
  width: 100px;
  border-radius: 6px;
  border: 1px solid #DDDDDDDD;
  margin-bottom: 5px;
  margin-left: auto;
  height: 24px;
  font-size: 12px;
  font-style: italic;
  font-weight: 100;
}

.btn-load-more {
  display: flex;
  color: black;
  font-style: italic;
  margin: 0px auto;
  border: none;
  background-color: white;
  cursor: pointer;
}

.notification-date {
  background-color: #FFF;
  /* width: 85px; */
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
  margin-top: 5px;
}

.navbar-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.account-logo-premium {
  display: flex;
  flex-direction: column;
}

.premium-avatar {
  width: 25px;
  position: relative;
  top: 38px;
  left: 15px;
  height: 25px;
  z-index: 1;
  margin: 0px 0px -10px 6px
}

.modal-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 272px;
  height: 180px;
  z-index: 15;
}

.dropdown-profile {
  display: flex;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.profile-left {
  flex: 0 0 40px;
  align-items: center;
}

.profile-logo {
  width: 30px;
  height: 30px;
  border-radius: 24px;
}

.profile-right {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.profile-name {
  font-weight: bold;
  color: #000000;
  font-weight: 600;
  font-size: 16px;

  margin: 0;
}

.profile-email {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.menu-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* styling for get credit */
.credit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 10px;
  text-align: center;
  transition: background-image 0.3s ease, background-color 0.3s ease;
}

.credit-container.premium {
  height: 40px;
  background-image: url('../assets/premiumbanner.png');
  background-color: #190433;
}

.credit-container.non-premium {
  height: 40px;
  background-image: url('../assets/Banner.png');
}

/* Centered content */
.credit-content {
  position: relative;
  color: white;
}

.credit-heading {
  font-size: 15px;
  color: #FFFFFF;
  line-height: 1.5
}

.credit-link {
  color: #ffffff;
  /* White text */
  text-decoration: none;
  /* Remove underline */
}

.credit-link:hover {
  text-decoration: underline;
  /* Add underline on hover */
}

/* Right-aligned icon */
.credit-icon {
  margin-left: auto;
  line-height: 15px;

}

.credit-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 40px;
}

.upload-button {
  background-color: black;
  font-size: 12px;
  ;
  color: white;
  border-radius: 20px;
  border: none;
  width: 94px;
  height: 24px;
  font-weight: 400;
  margin-left: 15px;

}



/* Recruiter dashboard chart styling */
.chart {
  background-color: #F5F7FA;
  /* height:800px; */
  width: 100%;

}

.chart-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 24px 38px 20px 38px;
}

.left-container {
  width: 50%;
  height: 736px;
  background-color: white;
  border-radius: 16px;
}

.right-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 736px;
  width: 50%;
}

.one-part {
  height: 524px;
  background-color: white;
  border-radius: 16px;
}

.second-part {
  height: 202px;
  background-color: #F1E5FF;
  border-radius: 16px;
}

/* recruiter dashbaord credit chart styling */
.credit-chart {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.one {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.heading {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px
}

.heading h3 {
  font-weight: 600;
  font-size: 20px;
  color: #1A1A1A;

}

.heading p {
  color: #4D4D4D;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  /* align-items: center; */

}

.heading-desc {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #666;
  gap: 10px;
}

.heading-desc-first {
  color: #7600FF;
  font-weight: 600;
  font-size: 16px;
}

.heading-desc-second {
  color: #1A1A1A;
  font-weight: 400;
  font-size: 12px;
}

.progress-bar-container {
  width: 100%;
  text-align: left;
}

.progress-bar {
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #B572AB;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
}

.progress-bar-container p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #1A1A1A;

}

/* Resume Section Styling */
.resume {
  background-color: #F5F7FA;
  /* height:800px; */
  width: 100%;
}

.resume-container {
  padding: 20px;
  background-color: #FFF;
}

.resume-ad {
  padding: 20px;
  background-color: #FFF;
}

.resume-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin: 0;
  /* border-bottom: 1px solid #c2c2c2; */
  padding: 35px 26px 35px 35px;
  box-shadow: 0 0 20px #c2c2c2;
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}


.tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
}

.reset-tab {
  border: none;
  background-color: #FFF;
  cursor: pointer;
  font-weight: 400;
  font-style: italic;
  text-decoration: underline;
  margin-top: 20px;
  font-size: 16px;
}

.reset-tab:hover {
  color: #002DCC;
}

.resume-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.resume-count {
  font-weight: 400;
  font-size: 1.25rem;
  color: #1A1A1A;
  padding-left: 5px;
  margin-top: 20px;
}

.resume-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.toggle-switch {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #1A1A1A;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 15px;
}

.toggle-switch:hover {
  color: #002DCC;
}

.search-container {
  position: relative;
  width: 20%;
}

.search-icon {
  position: absolute;
  top: 46%;
  left: 14px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}

.search-bar {
  width: 100%;
  box-sizing: border-box;
  height: 41px;
  padding: 10px 40px 10px 40px;
  border-radius: 20px;
  border: 1px solid #DDDDDDDD;
  font-size: 16px;
  color: #4D4D4D;
}

.cross-icon-search-bar {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 18px;
  transform: translateY(-50%);
  height: 18px;
  cursor: pointer;
}

.filter-btn,
.upload-btn {
  padding: 5px 10px;
  width: 98px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-btn {
  background-color: #fff;
}

.upload-btn {
  background-color: #002DCC;
  color: white;
  font-weight: 400;
}

.resume-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.no-list-items {
  width: 100%;
  height: 200px;
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border: 1px solid #c2c2c257;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 0 20px #c2c2c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* credit empty popup styling */

.credit-plan-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.alert-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #CC0000;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.upload-or-plan {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.or-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.line {
  width: 2px;
  height: 50px;
  background-color: #4D4D4D;
}

.buy-to-get {
  border: 2px solid #002DCC;
  width: 70%;
}

.upload-to-get {
  width: 30%;
}

.buy-to-get,
.upload-to-get {
  width: 50%;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 8px;
  color: #102d47;
  /* border: 1px solid #4D4D4D; */
}

.pricing-same {
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.no-credit-popup-btn {
  border: none;
  border-radius: 8px;
  height: 30px;
}

.no-credit-popup-btn:hover {
  color: #002DCC;
}


/* table styling */
.table {
  margin: 10px 30px 0px 30px
}

.data-table {
  width: 100%;
  margin: 30px 0;
  font-size: 16px;
  text-align: left;
  border-radius: 24px;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #E6E6E6
}

.data-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  font-size: 14px;
  color: #333;
  position: relative;
}

.data-table td {
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  position: relative;
}

.data-table th:first-child {
  border-top-left-radius: 10px;
  /* border-right: 0.5px solid #E6E6E6; */
}

.data-table th:last-child {
  border-top-right-radius: 10px;
  /* border-right: 0.5px solid #E6E6E6;  */
}

.data-table th:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  /* Positioned to the right of the border */
  width: 4px;
  /* Slight width for shadow effect */
  height: 100%;
  box-shadow: 4px 4px 8px 0px #00000014;
  z-index: 1;
}

.data-table th:last-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  width: 4px;
  height: 100%;
  box-shadow: 4px 4px 8px 0px #00000014;
  z-index: 1;
}

.data-table td:first-child {
  border-bottom-left-radius: 10px;
  /* border-right: 0.5px solid #E6E6E6; */
  position: relative;
}

.data-table td:last-child {
  border-bottom-right-radius: 10px;
  /* border-right: 0.5px solid #E6E6E6; */
  position: relative;
}

/* Adding shadow after the vertical line */
.data-table td:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  width: 4px;
  height: 100%;
  box-shadow: 4px 4px 8px 0px #00000014;
  z-index: 1;
}

.data-table td:last-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  width: 4px;
  height: 100%;
  box-shadow: 4px 4px 8px 0px #00000014;
  z-index: 1;
}


.header-content {
  display: flex;
  align-items: center;
}

.sort-icons {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  gap: 2px;
}

.sort-icon {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.skill-badge {
  border-radius: 24px;
  padding: 4px 8px;
  margin: 4px 10px;
  display: inline-block;
  border: 1px solid #999999;
  color: #4D4D4D;
  font-size: 12px;

}

.work-history {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.work-history-card {
  margin-left: 10px;
  font-size: 15px;
}

.badge-color {
  background-color: yellow;
}

.skill-badge.highlight {
  background-color: #ffff0069;
  border: none;
  font-weight: bold;
}

.work-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.work-badge {
  font-size: 14px;
  align-items: center;
}

/* table pagination */

.pagination-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.page-btn {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #F5F7FA;
}

.page-btn.active {
  background-color: #002DCC;
  color: white;
  font-weight: 600;
}

/* resume pagiantion */

.pagination {
  display: flex;
  list-style: none;
  justify-content: right;
  padding: 0;
  gap: 0px;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  font-size: 14px;

}

.pagination li a {
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #002DCC;
  color: white;
  border-color: #aaa;
}

.pagination li a:hover {
  background-color: #002DCC;
  color: white
}


/* resume  upload styling */

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 560px;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}

.upload-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}

.upload-heading h6 {
  font-size: 18px;
  font-weight: 400;
}

.close {
  cursor: pointer;
}

/* Drag-and-Drop Area */
.drag-drop-area {
  border: 2px dashed #ccc;
  padding: 90px;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
  height: 250px;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.drag-drop-area:hover {
  border-color: #002DCC;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-icon {
  width: 50px;
  height: 50px;
}

.share-icon {
  width: 28px;
  height: 28px;
  position: relative;
  top: 20px;
  border-radius: 50%;
  background-color: #002DCC;
}

.upload-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px 10px 0px;
}

.upload-detail {
  color: #999999;
  font-size: 12px;
  font-weight: 400;

}

/* Progress Bar */
.progress-bar-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.progress-bars {
  height: 8px;
  background-color: white;
  flex-grow: 1;
  border-radius: 4px;
  overflow: hidden;

}

.progress-bar-container span {
  font-size: 14px;
  color: #333;
}

/* Button Group */
.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.cancel {
  padding: 10px 20px;
  background: white;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #002DCC;
}

.upld-btn {
  padding: 10px 20px;
  background: #002DCC;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.button-group button:disabled {
  background-color: #E6E9F2;
  color: #999999;
  cursor: not-allowed;
}

/* Cancel Icon for File */
.cancel-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.cancel-icon:hover {
  opacity: 1;
}

/* File Info Container */
.file-info {
  align-items: center;
  background-color: #E6E9F266;
  border: 1px solid #eee;
  padding: 20px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.file-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file-size {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.file-size p {
  font-size: 16px;
  font-weight: 400;
  color: #1A1A1A;
}

.file-size span {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}

/* filter modal styling */

.filter-modal {
  position: absolute;
  top: 179px;
  right: 135px;
  width: 320px;
  height: 195px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  z-index: 100;
}

.filter-content {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.location-input {
  width: 99%;
  height: 41px;
  font-weight: 400;
  color: #4D4D4D;
  border: 1px solid #DDDDDDDD;
  border-radius: 20px;
  font-size: 16px;
  padding: 15px;
}

.filter-dropdown {
  position: relative;
}

/* .filter-dropdown input {
  font-size: 16px;
  width: 240px;
  height: 41px;
  font-weight: 400;
  color: #4D4D4D;
  border: 1px solid #DDDDDDDD;
  border-radius: 20px;
  padding: 15px;
} */


.skill-suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  max-height: 250px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: clip;
  z-index: 10;
  /* width: calc(100% - 1px); */
  width: calc(100% + 20px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
  min-width: 200px;
  max-width: 400px;
  width: auto;
  overflow-x: auto;
}

.skill-suggestions {
  top: 42px;
  left: 0px;
}

.skill-suggestion {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.skill-label.highlighted {
  font-weight: bold;
}

.highlighted {
  background-color: #f1ececed !important;
}

.skill-suggestion {
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
}

.city-suggestion.active,
.skill-suggestion.active {
  background-color: #f0f0f0;
  cursor: pointer;
}

.city-suggestion {
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
}

.filter-dropdown {
  display: flex;
  justify-content: space-between;
  width: 15%;
}

.pac-container {
  width: auto !important;
  min-width: 190px;
  font-size: 16px !important;
  z-index: 10000 !important;
}



.pac-item {
  padding: 5px;
  cursor: pointer;
}


.filter-dropdown label {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 400;
  color: #4D4D4D;
  white-space: nowrap;
  /* Ensure the label stays on one line */
}

.filter-dropdown select {
  width: 99%;
  height: 41px;
  padding: 6px;
  font-size: 16px;
  color: #4D4D4D !important;
  border: 1px solid #DDDDDDDD;
  border-radius: 20px;
}

.searchMetrics {
  width: 95%;
  height: 634px;
}

.searchTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0px 0px 30px;
}

.searchFilter {
  display: flex;
  height: 31px;
  margin: 10px 0px 10px 30px;
}

.filterStyle {
  font-size: 14px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 20px;
  background-color: white;
  width: 80px;
  text-align: center;
}

.searchgraphtitle {
  font-size: 20px;
  font-weight: 600;
  color: rgb(26, 26, 26);
  /* width: 59%; */
}

/* stylong for accoutn setting upate */
.account-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.account-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 592px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: absolute;
  text-align: center;
  margin-top: 1px;
  overflow-y: auto;
  transition: transform 0.5s ease-out;

}

.profile-picture-heading {
  display: flex;
  align-items: start;
  margin: 8px 0px 5px 5px;
}

.account-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 5px 10px 5px;
}

.account-heading h2 {
  font-size: 20px;
  font-weight: 600;
  color: #1A1A1A;
}

.edit-user-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-type {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 5px;

}

.form-input-account {
  border: 1px solid #DDDDDD;
  font-size: 14px;
  font-weight: 400;
  width: 320px;
  height: 44px;
  padding: 20px;
  color: #1A1A1A;
  border-radius: 8px;
  border: 1px solid #DDDDDD
}

.save-button {
  width: 154px;
  height: 42px;
  background-color: #E6E9F2;
  color: #999999;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 390px;
  border: none;
}

.save-button:enabled {
  cursor: pointer;
  background-color: #002DCC;
  color: white;
}

/* Close icon styling */
.close-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Profile Image and Buttons */
.image-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 25px;
}

.profile-picture {
  width: 112px;
  height: 112px;
  border-radius: 100px;
}

.image-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.change-picture-btn {
  background-color: white;
  color: #002DCC;
  border: 1px solid #002DCC;
  padding: 8px 12px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

.delete-picture-btn {
  color: #CC0000;
  margin-bottom: 10px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #FFFFFF;
}

.change-password-btn {
  /* margin-top:7px; */
  margin-bottom: 5px;
  background-color: white;
  color: #002DCC;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #002DCC;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  width: 164px;
  height: 39px;
}

.password-show-email {
  position: absolute;
  right: 260px;
  top: 340px;
}

.toggle-icon {
  width: 24px;
  height: 24px;
}

/* credit section styling */
.credit-section {
  background-color: #F5F7FA;
  min-height: 770px;
  width: 100%;

}

.credits {
  display: flex;
  flex-direction: column;
}

.uppar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 40px 15px 40px;
  align-items: center;
}

.uppar h3 {
  font-size: 32px;
  font-weight: 400;
  color: #1A1A1A;
}

.uppar p {
  font-size: 14px;
  font-weight: 600;
  color: #1A1A1A;
  display: flex;


}

.lower {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 40px 15px 40px;
}

.left-credit {
  width: 100%;
  /* height: 600px; */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.right-section-credit {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.right-credit-left {
  width: 50%;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.right-credit-right {
  width: 50%;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.right-credit hr {
  width: 100%;
  border: 1px solid #E6E6E6
}

.payment-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 5px 20px;
}

.payment-heading span {
  font-size: 20px;
  font-weight: 600;
  color: #1A1A1A;
}

.payment-heading p {
  color: #999999;
  font-size: 14px;
  font-weight: 400;

}

.payment-summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px 5px 20px;
}

.payment-summary hr {
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid #E6E6E6
}

.payment-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px 10px 0px;

}

.payment-list span {
  font-size: 18px;
  font-weight: 400;
  color: #1A1A1A;
}

.payment-list p {
  font-size: 14px;
  font-weight: 600;
  color: #1A1A1A;
}

.payment-method {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 5px 20px;
}

.payment-method h3 {
  font-size: 18px;
  font-weight: 700;
  color: #1A1A1A
}

.payment-method p {
  font-size: 14px;
  font-weight: 400;
  color: #002DCC;
  cursor: pointer;

}

/* card styling */
.card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.card-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.upload-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h6 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.close {
  width: 20px;
  cursor: pointer;
}

.input-container {
  position: relative;
  width: 100%;
}

.payment-btn {
  border: 1px solid #002DCC;
  width: 100%;
  color: white;
  margin-top: 10px;
  background-color: #002DCC;
  border-radius: 8px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}





.payment-plan {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 20px;
}

.plan-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  height: 168px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}

.selected-plan {
  border: 2px solid #002DCC;
  box-shadow: 0px 0px 0px 4px #002DCC1A;
}

.plan-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.plan-time label {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #1A1A1A
}

.plan-detail hr {
  width: 100%;
  border: 1px solid #E6E6E6
}

.plan-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}

.plan-info p {
  font-size: 14px;
  font-weight: 400;
  color: #1A1A1A;
}

.payment-history p {
  font-size: 25px;
  font-weight: 400;
  color: #1A1A1A
}

/*  recruiter resume section filters option styling */
.selected-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-tab {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #999999;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #4D4D4D;

}

.filter-tab span {
  margin-left: 8px;
  cursor: pointer;
  color: #000000;
  font-weight: 200
}

/* candidate detail styling */
.candidate-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white
}

.detail-heading {
  margin: 20px;
  font-weight: 600;
}

.section-heading {
  font-weight: 600;
}

.candidate-details {
  display: flex;
  flex-direction: row;
  width: 757px;
  min-height: 550px;
  background-color: blue;
  border-radius: 5px;

}

.detail-heading {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 150px;
  justify-content: flex-start;
  padding: 40px;
  gap: 5px;
  background: linear-gradient(to right, #ffffff, #d3d3d3);
  border: 2px solid #DDDDDDDD;
  border-radius: 8px;
  margin-top: 50px;
}

.section {
  display: flex;
  width: 90%;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
}

.left-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-section-one {
  width: 100%;
  padding: 30px;
  border: 2px solid #DDDDDDDD;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-section-one h4 {
  font-weight: 600;
  margin-bottom: 5px;
}

.detail-with {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

}

.headings {
  font-size: 15px;
  font-weight: 600;
  color: black;
}

.desc {
  font-size: 15px;
}

.detail-with-project {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.right-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right-section-one {
  width: 100%;
  padding: 30px;
  border: 2px solid #DDDDDDDD;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.right-section-one h4 {
  margin-bottom: 5px;
  font-weight: 600;
}

.detail-with-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
}

.detail-image {
  background-color: black;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.social-detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.work-industry {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
}

.work-industry-company {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 15px;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 10px;
}

.list-skill {
  padding: 6px 0px;
  color: #333;
  font-size: 15px;
}

.see-more {
  margin-top: 7px;
  cursor: pointer;
  color: #002DCC;
  text-decoration: none;
  font-size: 13px;
}

.btnss {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: blue;
  border: 1px solid #DDDDDDDD;
  ;
  border-radius: 8px;
  height: 50px;
  padding: 10px;
}

.btnss h3 {
  font-weight: 200;
}

.btnscandidate {
  align-items: center;
  border: none;
  display: flex;
  gap: 5px;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}

.btnscandidate div {
  text-decoration: none;
}

.project-item {
  margin-top: 5px;
}

.detail-with-right-industry {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 70%;
}



/* .btnss div:hover{
background-color:
} */
.candidate-preview {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 600px;
  overflow: hidden;
  width: 90%;
}

.candidate-preview div {
  display: block;
  margin: 0 auto;
  border: none;
  /* height: calc(100vh - 150px);  */
  width: 50%;
  overflow: hidden;
}

/* admin Dashbard */

/* dashboard styling */

/* Sidebar Styles */
.sidebar {
  /* position: absolute;
  left: 0;
  top: 60px;
  bottom: 0; */
  width: 68px;
  background-color: white;
  color: black;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  border-right: 1px solid rgb(230, 230, 230);
  transform: translateX(0);
  transition: transform 0.8s ease-out, width 0.8s ease-out;
}

.sidebar.open {
  width: 195px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  transform: translateX(0);
}

.sidebar.open .sidebar-toggle {
  margin-left: 115px;
}

.sidebar-toggle {
  margin-top: 15px;
  cursor: pointer;
  transition: margin-left 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@keyframes slideforward {
  from {
    width: 68px;
    transform: translateX(-68px);
  }

  to {
    width: 220px;
    transform: translateX(0);
  }
}

@keyframes slidebefore {
  from {
    width: 220px;
    transform: translateX(0);
  }

  to {
    width: 68px;
    transform: translateX(-68px);
  }
}


.sidebar-item a {
  text-decoration: none;
  color: inherit;
}

.sidebar-item {
  list-style: none;
  display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;
}

.sidebar-item {
  cursor: pointer;
  color: #999999;
}

.sidebar-item a {
  text-decoration: none;
  color: inherit;
  display: flex;
  gap: 10px;
  align-items: center;
}

.sidebar-item a:hover {
  color: #002DCC;
}

.sidebar-item span {
  display: inline-block;
}

.sidebar-item a.active-link {
  color: #002DCC;
}

.sidebar-content ul li {
  margin-bottom: 30px;
  list-style: none;
}

/* Main dashboard container */
.admin-dashboard-container {
  display: flex;
  width: 100%;
  background-color: #F5F7FA;
}

.admin-dashboard-container .sidebar {
  flex-shrink: 0;
}

.admin-dashboard-container .admin-dashboard-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 10px 25px 15px 25px;
}

.admin-dashboard-content {
  width: 100%;
  margin-left: 0px;
}

.admin-dashboard-content.sidebar-open {
  margin-left: 8px;
}

.admin-dashboard-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-dashboard-heading p {
  font-size: 16px;
  font-weight: 400;
}

.admin-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.admin-content h5 {
  font-size: 24px;
  font-weight: 600;
  color: #1A1A1A;
}

.admin-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-upper-chart {
  display: flex;

  flex-direction: row;
  gap: 10px;
  height: 444px;
}

.upper-left {
  width: 50%;
  background-color: white;
  border-radius: 16px;

}

.upper-right {
  width: 50%;
  background-color: white;
  border-radius: 16px;
}

.admin-lower-chart {
  background-color: white;
  border-radius: 16px;
  height: 510px;
}

/* Admin Integration section Styliong */

.integration-content {
  width: 100%;
  min-height: 750px;
  background-color: white;
  border-radius: 16px;
  display: flex;
}


.integration-form {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  gap: 10px;

}

.first-setting-section {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.integration-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 396px;
  gap: 2px;
}

/* recruiter styling */

.recruiter-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}

.actions {
  position: relative;
  display: inline-block;
}

.three-dots-icon {
  width: 20px;
  cursor: pointer;
}

.status-tab {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  min-width: 80px;
}

.status-tab.active {
  background-color: #41CC0026;
}

.status-tab.removed {
  background-color: #99999926;
}

.status-tab.suspended {
  background-color: #CC000026;
}

.dropdown-menu-status {
  position: absolute;
  top: 100%;
  left: -140px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  min-width: 120px;
  white-space: nowrap;
}

.dropdown-item-status {
  padding: 8px 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.dropdown-item-status:hover {
  background-color: #f0f0f0;
  ;
}

.dropdown-item-status img {
  margin-right: 8px;
}

/* marketing-dashboard  styling*/
.marketing-dashboard {
  display: flex;
  flex-direction: column;
  background-color: #F5F7FA;
  padding: 10px 25px 15px 25px;

}

.marketing-dashboard-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.marketing-dashboard h3 {
  font-weight: 600;
  font-size: 24px;
  color: #1A1A1A;
  margin: 15px 0px 15px 0px;

}

.email-box {
  width: 100%;
  min-height: 750px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.sample-download {
  text-align: right;
  padding: 17px 22px 0px 0px;
  color: #002DCC;
  cursor: pointer;
}


.box {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  gap: 15px;
  padding: 10px;

}

.marketing-subject-csv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.text-desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.marketing-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.csv-detail {
  display: flex;
  align-items: center;
}


.csv-btn {
  width: 134px;
  color: #002DCC;
  background-color: white;
  height: 44px;
  border: 1px solid #002DCC;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer
}

.csv-btn:disabled {
  background-color: hsl(225, 32%, 93%);
  color: #999999;
  border: none;
  font-weight: 600;
}

.upload-csv {
  background-color: #E6E9F2;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 10px;
  color: #002DCC;
  font-size: 12px;
  border: 1px solid #002DCC;
}


.btn-marketing {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
}

.btn-marketing button {
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.send-btn {
  width: 126px;
  background-color: #002DCC;
  color: white;
  border: none;
  cursor: pointer;
}

.reset-btn {
  width: 72px;
  color: #002DCC;
  background-color: white;
  border: none;
  cursor: pointer;
}

.btn-marketing button:disabled {
  color: #999999;
  border: none;
  cursor: not-allowed;
  opacity: 0.6;
}


.no-data {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #555;
}

.data-table tbody tr td.no-data {
  display: table-cell;
  vertical-align: middle
}

.data-table tbody tr.hover-row:hover {
  background-color: #f1ececed !important;
}

.data-table tbody tr.hover-row:hover td {
  background-color: #f1ececed !important;
}

.sort-icon-container {
  display: inline-block;
  vertical-align: middle;
}

.header-cell {
  position: relative;
  padding-right: 20px;
  /* Space for the icon */
}

.list-container {
  width: 100%;
  /*border: 1px solid #c2c2c257;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    box-shadow: 3px 4px #c2c2c247;*/
}

.list-items {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  border: 1px solid #c2c2c257;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 0 20px #c2c2c2;
}

.card {
  display: flex;
  min-height: 300px;
}

.card-left {
  width: 70%;
  padding: 10px 20px;
  border-right: 1px solid #c2c2c238;
  box-shadow: 2px 0px #c2c2c266;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-right {
  width: 30%;
  padding: 10px 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.designation {
  font-size: 15px;
  margin-bottom: 10px;
}

.experience {
  font-size: 15px;
  margin-bottom: 10px;
}

.designation-container {
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.skills {
  font-size: 15px;
  line-height: 25px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;

}

.card-title:hover {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #002DCC;
  cursor: pointer;
  text-decoration: underline;

}

.work-history-heading {
  display: flex;
  align-items: center;
}

.work-icon {
  width: 3%;
  float: left;
  margin-top: 2px;
}

.highlight {
  background-color: #ffff0069;
  font-weight: bold;
}

.location.highlight {
  background-color: #ffff0069;
  border-radius: 4px;
  padding: 2px 4px;
}


.company.highlight {
  background-color: #ffff0069;
  color: black;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 4px;
}

.payment-form {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 16px;
}

.payment-form-title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  color: #1A1A1A;
}

.payment-form-group {
  margin-bottom: 15px;
}

.payment-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
}

.card-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
}

.card-input:focus {
  border-color: #007bff;
  outline: none;
}

.payment-btn:disabled {
  background-color: #ccc;
  border: none;
  cursor: not-allowed;
}

.payment-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.payment-error-message {
  font-size: 14px;
  color: red;
}


.pdf-section {
  width: 90%;
  margin: 0 auto;       
  height: 100vh;       
  position: relative;     
  border: 2px solid #ddd;
  background-color: #f4f4f4;
  border-radius: 5px;
}
.pdf-scroll {
  height: 100%;
  overflow-y: auto;   
}

.pdf-container {
  position: relative;
  background-color: #f4f4f4;  
  max-width: 100%;
  min-height: 100vh;
  border-radius: 5px;
  margin: 0 auto;  
}

.pdf-viewer-container .rpv-toolbar {
  display: none !important;
}

.rpv-core__highlight {
  background-color: red;
  border-radius: 3px;
}

.pdf-error {
  color: #dc3545;
  padding: 1rem;
  text-align: center;
}


.pdf-container .react-pdf__message {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.download-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  padding: 10px 20px;
  background-color: #002DCC;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  display: none;
  z-index: 10;
}

.download-button span {
  display: flex;
  height: 30px;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.pdf-section:hover .download-button {
  display: block;
}

.skill-item {
  border: 1px solid #ccc;
  border-radius: 24px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.skill-item.highlight {
  background-color: #ffff0069;
  font-weight: bold;
  border: none;
}

.headings.highlight {
  background-color: #ffff0069;
  border-radius: 24px;
  padding: 1px;
}


/*  resume List Skeleton styles */
.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skeleton-card {
  display: flex;
  gap: 16px;
  background: white;
  padding: 16px;
  width: 100%;
}

.skeleton-card-left,
.skeleton-card-right {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 5px;
  flex: 1;
}

.skeleton-card-left {
  flex: 0 0 70%;
}

.skeleton-card-right {
  flex: 0 0 30%;
}

.skeleton-title,
.skeleton-subtitle,
.skeleton-text,
.skeleton-subtext {
  height: 15px;
  margin-bottom: 10px;
  background: lightgray;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skeleton-title {
  width: 30%;
}

.skeleton-subtitle {
  width: 50%;
}

.skeleton-subtext {
  width: 20%;
}

.shimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 200%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    left: -200%;
  }

  100% {
    left: 100%;
  }
}


/* table skeleton loader */




.skeleton-row {
  width: 100%;
  height: 20px;
  border-radius: 8px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* notes section */
.notes-section {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 999;
}

.notes-icon {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.notes-icon:hover {
  transform: scale(1.1);
}

.notes-icon:active {
  transform: scale(0.95);
}

.chat-container {

  position: fixed;
  bottom: 90px;
  right: 60px;
  z-index: 1000;
  border-radius: 8px;
  padding: 10px;
}

.chat-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  min-height: 100px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
}

.note-save-btn {
  padding: 10px 20px;
  background-color: #002DCC;
  color: #fff;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.chat-box {
  flex-grow: 1;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.sticky-notes-grid {
  gap: 10px;
  margin-top: 10px;
  width: 90%;
}

.sticky-note {
  background-color: #fafafa;
  border-radius: 16px;
  padding: 10px;
  position: relative;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.note-text-section {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  width: 100%;
}

.note-text {
  font-size: 9px;
  margin-left: auto;
  font-weight: 500;
  color: #333;
}

/* update admin modal  search styling */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.modal-actions button {
  width: 60px;
  height: 30px;
  background-color: #002DCC;
  border: none;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.search-container-recruiter {
  position: relative;

}

/* resumse section page style */

.custom-pagination {
  display: flex;
  gap: 8px;
  justify-content: right;
  margin-top: 20px;

}

.custom-pagination button {
  padding: 8px 12px;
  border: none;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
}

.custom-pagination button.active {
  background-color: #002DCC;
  color: white;
  border-color: none;
}

.custom-pagination button:hover:not(:disabled) {
  background-color: #002DCC;
  color: white;
}

.custom-pagination button:disabled {
  background-color: white;
  color: black;
}

.custom-pagination .dots {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
}


/* create user in super admin styling */
.form-type-pattern {
  display: flex;
  flex-direction: row;
}

/* toolt tip  */
.custom-tooltip {
  background-color: #002DCC !important;
  ;
  color: #FFF !important;
  font-size: 12px !important;
  border-radius: 8px !important;
}

/* card sty */
.card-number-container {
  display: flex;
  align-items: center;
  position: relative;
}

.card-input {
  flex: 1;
  padding-right: 50px;
}

.card-brand-icon {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 14px;
  color: #6c757d;
  white-space: nowrap;
}

.stats-select {
  height: 35px;
  width: 85px;
  padding: 5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 8px;
}

/* google ad sense styling */

.form-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-labels {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-label-primary {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.form-label-secondary {
  font-size: 14px;
  color: #777;
}

.form-toggle {
  display: flex;
  align-items: center;
}

.form-toggle input[type="checkbox"] {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-toggle input[type="checkbox"]:checked {
  background-color: #002DCC;
}

.form-toggle input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.form-toggle input[type="checkbox"]:checked::before {
  transform: translateX(20px);
}

/* custom  scroll bar  */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #999999, #999999);
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #999999, #999999);
}


/* premium user credit section */
.premium-plan {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px;
  text-align: left;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.premium-desc {
  padding: 15px 0px 15px 15px;
  font-size: 15px;
}

.premium-text {
  font-weight: 600;
  color: white;
  font-size: 16px;
  background-image: url("../assets/Banner.png");
  padding-top: 0;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 31px;
}

.premium-cancel {
  padding: 0px 0px 15px 15px;
}

.premium-cancel-button {
  font-weight: 400;
  font-size: 14px;
  color: #1A1A1A;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 15px;
}

.premium-cancel-button:hover {
  color: #002DCC;
}

.saved-cards {
  padding: 20px;
}

.card-desc {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  border-radius: 8px;
  padding: 15px;
  border: 2px solid #002DCC;
  box-shadow: 0px 0px 0px 4px #002DCC1A;
}

.card-desc-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center
}

.card-desc-default {
  color: #999999;
  font-size: 12px; 
  margin-top: 10px;
}

.card-add-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;


}

.card-add-new button {
  border: none;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;

}

.card-add-new button:hover {
  border: none;
  color: #002DCC;
  background-color: #fff;
  font-size: 16px;
}

.card-form {
  width: 93%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 16px;
}

.card-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-head-title {
  font-weight: 600;
}
