/* styling for home page */
.home-navbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .home-nav-container {
    background-color: white;
    border-color: #e5e7eb;
    padding: 0.625rem 0;
  }
  
  .nav-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .logo img {
    height: 7.25rem;
    margin-right: 0.75rem;
  }
  
  .menu-toggle {
    display: flex;
    align-items: center;
  }
  
  .menu-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    margin-left: 0.25rem;
    font-size: 0.875rem;
    color: #6b7280;
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .menu-button:hover {
    background-color: #f3f4f6;
  }
  
  .menu-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e5e7eb;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }
  
  .menu {
    display: none;
    width: 100%;
  }
  
  .menu.open {
    display: block;
  }
  
  .menu-list {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    font-size: 0.875rem;
    list-style: none;
    padding: 0;
  }
  
  .menu-list li {
    margin-bottom: 0.5rem;
  }
  
  .menu-list a {
    display: block;
    padding: 0.5rem 0.75rem;
    color: #374151;
    text-decoration: none;
    font-weight: 600;
    border-radius: 0.375rem;
    transition: color 0.3s ease;
  }

  .menu-list a.active {
    color: blue; 
  }
  
  .menu-list a:hover {
    color: #002DCC;
  }
  
  /* Responsive styles */
  @media (min-width: 1024px) {
    .menu-toggle {
      display: none;
    }
  
    .menu {
      display: flex;
      width: auto;
    }
  
    .menu-list {
      flex-direction: row;
      margin-top: 0;
    }
  
    .menu-list li {
      margin-bottom: 35px;
      margin-right: 0;
    }
  
    .menu-list a {
      padding: 0.5rem 1rem;
    }
  }
  
  .hero-section {
    background-color: #ffffff;
    z-index: 1;
    
  }
  
  .hero-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 1rem;
    padding-top: 7rem;
    /* padding-bottom: 2rem; */
    display: grid;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .hero-container {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      padding-top: 8.85rem;
      /* padding-bottom: 4rem; */
    }
  }
  
  .hero-content {
    grid-column: span 7;
    padding-top: 6rem;
  }
  
  .hero-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
    color: black
  }
  .hero-subheading{
    font-size: 1.25rem;
    line-height: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: black
  }
  
  @media (min-width: 768px) {
    .hero-heading {
      font-size: 2rem;
      line-height: 1;
    }
    .hero-subheading{
      font-size: 1rem;
      line-height: 1;

    }
  }
  
  .our-partners{
    z-index: 0;
    background-color: #edeae5;
    margin-top: -120px;  /* Pull the section up */
    height: 160px;
  }
  @media (min-width: 1280px) {
    .hero-heading {
      font-size: 2.75rem;
      line-height: 1.5;
    }
    .hero-subheading {
      font-size: 1.75rem;
      line-height: 2;
    }
    .hero-content{
      padding-top:8rem;
    }
  }
  
  
  .hero-description {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #6b7280;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .hero-description {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  
  .hero-link {
    color: inherit;
    text-decoration: none;
    margin: 0 0.25rem;
  }
  
  .hero-link:hover {
    text-decoration: underline;
  }
  
  .hero-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  @media (min-width: 640px) {
    .hero-buttons {
      flex-direction: row;
    }
  }
  
  
  .hero-image-container {
    display: none;
  }
  .our-partners{
    display: none;
  }
  
  @media (min-width: 1024px) {
    .hero-image-container {
      display: block;
      z-index: 1;
      grid-column: span 5;
    }
    .our-partners{
      display: block;
    }
  }
  
  .hero-image {
    width: 100%;
    height: auto;
  } 
  
  .home-logo-section {
    background-color: white;
    padding: 2rem 0;
  }
    
  .home-logo-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .home-logo-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    color: #6b7280;
  }
  
  .home-logo-grid.dark {
    color: #9ca3af;
  }
  
  .home-logo-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: color 0.3s ease;
  }
  
  .home-logo-link:hover .logo-icon {
    color: #1a1a1a;
  }
  
  .home-logo-link.dark:hover .logo-icon {
    color: white;
  }
  
  .home-logo-icon {
    height: 2.25rem;
    transition: color 0.3s ease;
  }
  
  @media (min-width: 640px) {
    .home-logo-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }
  }
  
  @media (min-width: 1024px) {
    .home-logo-grid {
      grid-template-columns: repeat(6, 1fr);
    }
    .home-logo-link {
      justify-content: center;
    }
  }

.feature-section {
  background-color: #F9FAFB; 
}


.feature-container {
  max-width: 1600px; 
  margin: 0 auto;
  padding: 2rem 1rem; 
}

@media (min-width: 1024px) {
  .feature-container {
    padding: 6rem 1.5rem; 
  }
}

.feature-container > .feature-row {
  margin-bottom: 3rem; 
}

.feature-container > .feature-row:last-child {
  margin-bottom: 0;
}

.feature-row {
  display: block;
}

@media (min-width: 1024px) {
  .feature-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
  }
}

@media (min-width: 1280px) {
  .feature-row {
    gap: 4rem; 
  }
}


.feature-text {
  color: #6B7280; 
  font-size: 1rem;
}


@media (min-width: 640px) {
  .feature-text {
    font-size: 1.125rem; 
  }
}

.feature-heading {
  margin-bottom: 1rem; 
  font-size: 1.875rem; 
  font-weight: 800; 
  letter-spacing: -0.025em;
  color: #111827;
}

.feature-paragraph {
  margin-bottom: 2rem;
  font-weight: 300; 
  font-size: 1rem;
}

@media (min-width: 1024px) {
  .feature-paragraph {
    font-size: 1.25rem; 
  }
}

.feature-list {
  padding-top: 2rem;
  margin: 1.75rem 0;
  border-top: 1px solid #E5E7EB; 
}

.feature-list-item {
  display: flex;
  align-items: center;
  gap: 0.75rem; 
  margin-bottom: 1.25rem; 
}

.feature-list-item:last-child {
  margin-bottom: 0;
}

.feature-icon {
  flex-shrink: 0;
  width: 1.25rem; 
  height: 1.25rem; 
  color: #002DCC; 
}


.feature-item-text {
  font-size: 1.25rem; 
  font-weight: 500; 
  line-height: 1.25;
  color: #111827; 
}

.feature-image {
  width: 100%; 
  margin-bottom: 1rem;
  border-radius: 0.5rem; 
  display: none;
}

@media (min-width: 1024px) {
  .feature-image {
    display: block;
    margin-bottom: 0;
  }
}

  

  .trusted-section {
    background-color: #ffffff;
  }
    
  .trusted-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 64px 16px;
    display: grid;
    gap: 64px;
  }
  
  @media (min-width: 1024px) {
    .trusted-container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 64px;
      padding: 60px 24px;
    }
  }
  
  .trusted-left {
    grid-column: span 2;
    margin-bottom: 32px;
  }
  
  .trusted-subtitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #002DCC;
    margin-bottom: 12px;
  }
  
  
  .trusted-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 800;
    color: #111827;
    margin: 12px 0 16px;
  }
  
  .trusted-description {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    color: #6b7280;
    margin-bottom: 24px;
  }
  

  
  .trusted-grid {
    grid-column: span 2;
    display: grid;
    gap: 48px;
  }
  
  @media (min-width: 768px) {
    .trusted-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 48px;
    }
  }
  
  .trusted-item {
    margin-bottom: 32px;
  }
  
  .trusted-icon {
    width: 40px;
    height: 40px;
    color: #002DCC;
    margin-bottom: 8px;
  }
  
  @media (min-width: 768px) {
    .trusted-icon {
      width: 48px;
      height: 48px;
    }
  }

  
  .trusted-item-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #111827;
    margin: 8px 0;
  }

  
  .trusted-item-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #6b7280;
  }
  
.pricing-section {
  background-color: #ffffff;
}


.pricing-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

@media (min-width: 1024px) {
  .pricing-container {
    padding:6rem 1.5rem 2rem 1.5rem;;
  }
}

.pricing-header {
  max-width: 768px;
  margin: 0 auto 3rem auto;
  text-align: center;
}

.pricing-header h2 {
  margin-bottom: 1rem;
  font-size: 2rem; 
  font-weight: 800;       
  letter-spacing: -0.025em; 
  color: #111827;
}

.pricing-header p {
  margin-bottom: 1.25rem;
  font-weight: 300;    
  font-size: 1.125rem;   
  color: #6B7280;
}


.pricing-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .pricing-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  height: 100%; 
  width: 24rem;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  color: #111827;
  border: 1px solid #F3F4F6; 
  border-radius: 0.5rem;     
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
  padding: 1.5rem;
}

.price-first {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 0rem 0rem 1rem;
}

.price-first span:first-child {
  margin-right: 0.5rem;
  font-size: 0.75rem;  
  font-weight: 800;   
}


.price-first span:last-child {
  color: #6B7280;
}

.badge {
  position: absolute;
  top: -11px;
  left: -10px;
  width: 57px; 
  height: auto;
  transform: rotate(-18deg);
  border-radius: 50%;
}


.pricing-card h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;  
  font-weight: 600;  
}

.pricing-card p {
  font-weight: 300;  
  font-size: 1rem;
  color: #6B7280;
}

@media (min-width: 640px) {
  .pricing-card p {
    font-size: 1.125rem;
  }
}

.price {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 1rem 0;
}

.price span:first-child {
  margin-right: 0.5rem;
  font-size: 3rem;  
  font-weight: 800;   
}

.price span:last-child {
  color: #6B7280;
}


.pricing-list {
  margin-bottom: 2rem;
  text-align: left;
  list-style: none;
  padding: 0;
}

.pricing-list li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.pricing-list li svg {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem; 
  color: #002DCC; 
}
.pricing-desc{
  margin: 0 auto 0rem auto;
  text-align: left;
}
.pricing-desc p{
  margin-bottom: 1.25rem;
  font-weight: 300;    
  font-size:12px;   
}


/* Button (Get started) */
.pricing-card a {
  display: inline-block;
  background-color: #002DCC; 
  color: #ffffff;
  padding: 0.625rem 1.25rem; 
  font-size: 0.875rem;     
  font-weight: 500;        
  border-radius: 0.5rem;      
  text-decoration: none;
  text-align: center;
}

.pricing-card a:hover {
  background-color: #002DCC; 
}

.faq-section {
  background-color: #ffffff; 
}


.faq-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 1.5rem; 
  padding-bottom: 2rem;
}

.faq-title {
  margin-bottom: 1.5rem; 
  font-size: 1.875rem;
  font-weight: 800; 
  text-align: center;
  color: #111827; 
}


.accordion {
  max-width: 768px; 
  margin: 0 auto;
}


.accordion-item {
  border-bottom: 1px solid #E5E7EB; 
}

.accordion-header span{
  font-size: 1.05rem;
}


.accordion-header button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0;
  font-weight: 500; 
  text-align: left;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #E5E7EB;
  cursor: pointer;
}

.accordion-header button span {
  color: #111827; 
}

.accordion-header button:not(.active) {
  color: #6B7280; 
}

.accordion-icon {
  width: 1.5rem; 
  height: 1.5rem; 
  transition: transform 0.3s ease;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
}

.accordion-body {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-body.closed {
  max-height: 0;
}

.accordion-body.open {
  max-height: 500px; 
}

.accordion-content {
  padding: 1.25rem 0;
  border-bottom: 1px solid #E5E7EB;
}


.accordion-content p {
  margin-bottom: 0.5rem;
  color: #6B7280;
}

.accordion-content a {
  color: #7C3AED; 
  text-decoration: none;
}

.accordion-content a:hover {
  text-decoration: underline;
}

.accordion-content ul {
  padding-left: 1.25rem;
  list-style-type: disc;
  color: #6B7280; 
}



  .free-trial-section {
    background-color: #f9fafb; 
  }
  
  .free-trial-container {
    max-width: 1600px;
    padding: 2rem 1rem;
    margin: 0 auto;
  }
  
  @media (min-width: 1024px) {
    .free-trial-container {
      padding: 4rem 1.5rem;
    }
  }
  
  .free-trial-text-container {
    max-width: 640px; 
    margin: 0 auto;
    text-align: center;
  }

  .free-trial-heading {
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.025em;
    color: #1F2937; 
  }

  .free-trial-paragraph {
    margin-bottom: 1.5rem;
    color: #6B7280; 
    font-size: 1.125rem; 
  }

  .free-trial-button {
    display: inline-block;
    color: #FFFFFF;
    background-color: #002DCC; 
    font-weight: 500;
    border-radius: 0.5rem;
    font-size: 0.875rem; 
    padding: 0.625rem 1.25rem; 
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .free-trial-button:hover {
    background-color: #002DCC; 
  }
  
  .free-trial-button:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(153, 132, 255, 0.5); 
  }
  
  
  .footer {
    background-color: #ffffff;
  }
  
  
  .footer-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 1rem;     
    padding-top: 1.5rem;   
    padding-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .footer-container {
      padding: 2rem;     
    }
  }
  
  @media (min-width: 1024px) {
    .footer-container {
      padding-top: 4rem;  
      padding-bottom: 4rem;
      padding-left: 2.5rem; 
      padding-right: 2.5rem;
    }
  }
  
  .footer-grid {
    display: grid;
    gap: 2rem; 
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 768px) {
    .footer-grid {
      grid-template-columns: repeat(3, 1fr); 
    }
  }
  
  @media (min-width: 1024px) {
    .footer-grid {
      grid-template-columns: repeat(4, 1fr); 
    }
  }
  
  .footer-title {
    margin-bottom: 1.5rem; 
    font-size: 0.875rem; 
    font-weight: 600;   
    text-transform: uppercase;
    color: #1F2937;   
  }
  
  .footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-list-item {
    margin-bottom: 1rem; 
  }
  
  .footer-link {
    color: #6B7280; 
    text-decoration: none;
    transition: text-decoration 0.2s ease;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-divider {
    margin: 1.5rem 0;   
    border: none;
    border-top: 1px solid #E5E7EB; 
  }
  
  
  .footer-bottom {
    text-align: center;
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;    
    font-weight: 600;   
    color: #1F2937;
    text-decoration: none;
  }
  
  
  .logo-img {
    height: 1.5rem; 
    margin-right: 0.75rem;
  }
  
  @media (min-width: 640px) {
    .logo-img {
      height: 2.25rem;
    }
  }
  
  .footer-copy {
    display: block;
    font-size: 0.875rem; 
    color: #6B7280;
    margin-bottom: 1rem;
  }
  .footer-inline-link {
    color: #002DCC; 
    text-decoration: none;
    transition: text-decoration 0.2s ease;
  }
  
  .footer-inline-link:hover {
    text-decoration: underline;
  }
  
  
  .footer-socials {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem; 
    gap: 1.25rem;    
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  .footer-social-link {
    color: #6B7280;
    transition: color 0.2s ease;
  }
  
  .footer-social-link:hover {
    color: #1F2937;
  }
  
  .footer-social-icon {
    width: 1.25rem; 
    height: 1.25rem;
  }
  
  html {
    scroll-behavior: smooth;
  }
  

  /* contact us styling */
  
.contact-section {
  background-color: #f9fafb; 
}

.contact-container {
  max-width: 1280px;
  padding: 10rem 1rem;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .contact-container {
    padding: 10rem 1.5rem;
  }
}

/* Headings and subheading */
.contact-heading {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1rem;
}

.contact-subheading {
  padding: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2rem;
}

.contact-form-wrapper {
  max-width: 600px;  
  margin: 0 auto;
}

.contact-us-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 768px) {
  .contact-us-form {
    grid-template-columns: 1fr 1fr;
  }
  .contact-us-form .submit-button {
    grid-column: span 2;
  }
}

.contact-us-form-group {
  display: flex;
  flex-direction: column;
}

.contact-us-form-label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.contat-us-form-input {
  padding: 0.5rem;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 44px;
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: small;
}

/* Submit button styling */
.contact-us-submit-button {
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: #002DCC;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-submit-button:hover {
  background-color: #002DCC;
}

.contact-us-form .submit-button {
  width: 100%;
  display: block;
}

.contact-us-form .submit-button {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .contact-us-form .contact-us-submit-button {
    grid-column: 1 / -1;
    width: 100%;
    display: block;
  }
}


/* privacy policy styling */
.privacy-subheading{
  padding: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2rem;
  text-align: left;
}

.privacy-policy-heading{
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1rem; 
}

.features-text {
  font-size: 16px;
  line-height: 2rem;
}

.features-headings {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;

}
.features-list{
  padding: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;
}


.team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards per row on large screens */
  gap: 20px;
  margin-top: 20px;
}

.team-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.team-img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.team-name {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  align-items: center;
}

.team-desc {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  padding:5px
}

/* Responsive Layout */
@media (max-width: 1023px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row for tablets */
  }
  .team-img{
    height: 300px;
  }
}

@media (max-width: 767px) {
  .team-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 card per row for mobile */
  }
  .team-img{
    height: 340px;
  }
}


/* Modal Overlay */
.modal-overlay-about {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content-about {
  background: #fff;
  padding: 20px;
  width: 850px;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Close Button */
.close-btn-about{
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease-in-out;
}
.about-us-desc {
  font-size: 1rem;
  padding: 27px;
  text-align: left;
  line-height: 22px;
}


@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}
